$color-text: #4A4A4A;
$color-text2: #3C3A41;
$color-yellow: #FEDE00;
$color-blue: #1E71BF;
$color-blue2: #4296EA;
$color-title: #551A8B;
$color-red: #F96346;
$color-red2: #FF3333;
$color-red3: #E70020;
$color-green: #46C37B;
$color-gray: #999999;
$color-bg: #FFF4E5;
$color-link: #CFABF1;
$color-border: #7F6498;
$color-dots: #E1E4FB;
$color-tooltip: #D8CCE3;
$adv-bg: #FFF7ED;
