@font-face {
	font-family: "ProximaNova-Regular";
	src: url("../fonts/ProximaNova-Regular/ProximaNova-Regular.eot");
	src: local('☺'), url("../fonts/ProximaNova-Regular/ProximaNova-Regular.woff") format('woff'), url("../fonts/ProximaNova-Regular/ProximaNova-Regular.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova-Bold";
	src: url("../fonts/ProximaNova-Bold/ProximaNova-Bold.eot");
	src: local('☺'), url("../fonts/ProximaNova-Bold/ProximaNova-Bold.woff") format('woff'), url("../fonts/ProximaNova-Bold/ProximaNova-Bold.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova-Semibold";
	src: url("../fonts/ProximaNova-Semibold/ProximaNova-Semibold.eot");
	src: local('☺'), url("../fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff") format('woff'), url("../fonts/ProximaNova-Semibold/ProximaNova-Semibold.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}
