@import "import/reset";
@import "import/fonts";
@import "import/vars";
@import "import/mixin";

body {
    font-family: "ProximaNova-Regular";
    color: #495057;
    overflow-x: hidden;
}

body.hidden {
    @media(max-width: 991px) {
        overflow-y: hidden;
    }
}

.main-text {
    font-size: 18px;
    color: #7F7F7F;

    a {
        color: #0B5CD0;
    }
}

.about-keys {
    max-width: 1960px;
    margin: 0 auto;
    padding: 15px 20px;
    margin-bottom: 21px;
    border-bottom: 8px solid #C00027;
}

.main-title {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    color: #495057;
    margin-bottom: 12px;
    text-align: center;
    @media(min-width: 768px) {
        font-size: 24px;
    }
    @media(min-width: 992px) {
        font-size: 36px;
    }
}

.request__checked {
    input {
        display: none;
    }

    input+label {
        font-size: 14px;
        color: $color-text2;
        display: inline-block;
        position: relative;
        padding-left: 32px;
        text-align: left;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: -2px;
            background: white;
            width: 20px;
            height: 20px;
            z-index: 5;
        }

        &:before {
            content: '';
            position: absolute;
            left: 1px;
            top: 1px;
            width: 18px;
            height: 14px;
            background: url("../img/check.png") no-repeat center;
            z-index: 10;
            display: none;
        }
    }

    input:checked + label {
        &:before {
            display: block;
        }
    }
}

.container {
    max-width: 1560px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
}

.car-model {
    margin-bottom: 59px;
    @media(min-width: 1280px) {
        margin-bottom: 0;
    }

    .main-title {
        margin-bottom: 62px;
    }
}

.model-slider,
.photo-slider {
    .owl-next,
    .owl-prev {
        position: absolute;
        width: 90px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10em;
        overflow: hidden;
        background: #FFFCC9 !important;
        @media(max-width: 1799px) {
            display: none;
        }
    }

    .owl-prev {
        left: -85px;
    }

    .owl-next {
        right: -85px;
    }

    .nav-left,
    .nav-right {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 10em;
            width: 80%;
            height: 80%;
            background: #FEF86E;
            z-index: 1;
        }

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 24px;
            height: 24px;
            z-index: 10;
        }
    }

    .nav-left:before {
        background: url("../img/arrow-left.png") no-repeat center;
    }

    .nav-right:before {
        background: url("../img/arrow-right.png") no-repeat center;
    }

    .owl-nav {
        height: 0;
    }

    .owl-dots.disabled {
        display: block;
    }

    .owl-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        @media(max-width: 767px) {
            display: none;
        }

        .owl-dot {
            display: inline-block;
            margin-right: 10px;

            span {
                float: left;
                display: inline-block;
                border-radius: 10em;
                background: #fff;
                width: 10px;
                height: 10px;
                background: #DEEAFB;
            }
        }

        .active {
            span {
                background: #E63141;
            }
        }
    }
}

.photo-slider {
    .owl-next,
    .owl-prev {
        width: 25px;
        height: 40px;
        background: none !important;
    }

    .owl-dots {
        display: block;
        bottom: -35px;
    }

    .owl-prev {
        left: -42px;
    }

    .owl-next {
        right: -42px;
        display: block;
        @media(max-width: 1279px) {
            right: 20px;
        }
    }

    .nav-left,
    .nav-right {
        width: auto;
    }

    .nav-left:before {
        width: 25px;
        height: 40px;
        background: url("../img/str-left.png") no-repeat center;
    }

    .nav-right:before {
        width: 25px;
        height: 40px;
        background: url("../img/str-right.png") no-repeat center;
        @media(max-width: 1279px) {
            background: url("../img/chevron-left.png") no-repeat center;
        }
    }
}

.photo-block {
    background: #FFF1A7;
    padding: 27px 0 50px;

    .main-title {
        margin-bottom: 20px;
    }
}

.photo-block__cont {
    max-width: 1920px;
    padding: 0 60px;
    width: 100%;
    margin: 0 auto;
    @media(max-width: 1279px) {
        padding: 0 16px;
    }
}

.model-list {
    margin-bottom: 46px;

    &__img {
        width: 50px;
        margin-bottom: 15px;
    }

    li {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 46px;
        }
    }

    a {
        font-size: 20px;
        color: #005BD1;
        border-bottom: 1px solid #005BD1;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }
}

.map-wrap {
    padding-top: 45px;
}

.map-block {
    max-width: 1920px;
    height: 420px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @media(min-width: 992px) {
        margin: 0 auto;
    }

    .arrival-calc {
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        top: 20px;
        @media(min-width: 992px) {
            left: 134px;
            top: 37%;
            transform: translateY(-50%);
        }
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
    z-index: 50;
    display: none;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    overflow-y: auto;
}

.arrival-calc {
    width: 320px;
    height: 216px;
    font-size: 15px;
    background: white;
    padding: 13px 15px 28px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    @media(min-width: 992px) {
        width: 340px;
        height: 240px;
    }

    &__title {
        font-family: "ProximaNova-Semibold";
        margin-bottom: 20px;
        font-size: 12px;
        color: #3C3A41;
        border-bottom: 1px solid #D3D3D3;
        @media(min-width: 992px) {
            font-size: 16px;
            text-align: center;
        }

        span {
            position: relative;
            padding-bottom: 9px;
            display: block;
            @media(min-width: 992px) {
                display: inline-block;
            }
        }
    }

    &__street {
        padding-left: 43px;
        margin-left: 12px;
        position: relative;
        margin-bottom: 25px;
        font-family: "ProximaNova-Semibold";

        &:after {
            content: 'А';
            position: absolute;
            font-family: "ProximaNova-Bold";
            font-size: 19px;
            left: 0;
            color: white;
            top: -5px;
            text-align: center;
            padding-top: 4px;
            width: 29px;
            height: 29px;
            background: #4496E9;
            border-radius: 10em;
        }
    }
}

.arrival-calc__total {
    font-family: "ProximaNova-Semibold";
    background: #FFF1A6;
    border-radius: 30px;
    padding: 5px 7px;
    color: #3C3A41;
    text-align: center;
    margin-top: 20px;
}

.arrival-btn {
    font-family: "ProximaNova-Semibold";
    width: 100%;
    height: 52px;
    text-align: center;
    background: #FFF429;
    color: #514158;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
}

.search-master {
    width: 100%;
}

.search-master__title {
    background: white;
    text-align: center;
    padding: 18px 10px 15px;
    margin-bottom: 40px;
    @media(min-width: 992px) {
        background: none;
    }
    @media(min-width: 1280px) {
        text-align: left;
        padding: 18px 10px 15px 25px;
    }

    span {
        font-family: "ProximaNova-Bold";
        color: #FF4345;
        display: inline-block;
        margin-bottom: 16px;
        text-transform: uppercase;
        font-size: 24px;
        position: relative;
        @media(min-width: 992px) {
            font-size: 36px;
            color: #FFE033;
            padding-left: 50px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 34px;
                height: 25px;
                background: url("../img/quote.png") no-repeat center;
                transform: rotate(180deg);
            }
        }
        @media(min-width: 1280px) {
            font-size: 42px;
        }
    }

    b {
        font-size: 20px;
        position: relative;
        @media(min-width: 992px) {
            font-size: 28px;
            color: #FFE033;
            padding-right: 50px;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 20px;
                width: 34px;
                height: 25px;
                background: url("../img/quote.png") no-repeat center;
            }
        }
        @media(min-width: 1280px) {
            font-size: 32px;
            padding-left: 50px;
        }

        i {
            font-family: "ProximaNova-Semibold";
        }
    }
}

.payment-inner {
    width: 248px;
    margin: 0 auto;
    @media(min-width: 992px) {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.banner__inner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
}

.chief-about {
    width: 180px;
    @media(max-width: 1279px) {
        display: none;
    }

    &__img {
        width: 175px;
        height: 175px;
        border-radius: 100%;
        overflow: hidden;
        border: 5px solid #FEDF3B;
        margin: 0 auto 18px;
    }

    &__article {
        font-family: "ProximaNova-Semibold";
        background: #FFF1A6;
        border-radius: 10px;
        padding: 8px 10px;
        color: #374957;
        background: #FFF1A7;
        font-size: 16px;

        span {
            font-family: "ProximaNova-Bold";
            font-size: 28px;
        }
    }
}

.payment__btn {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    width: 100%;
    height: 52px;
    color: #514158;
    background: #FFF429;
    box-shadow: 0 0 30px rgba(255, 244, 41, 0.7), 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: none;
}

.payment-item {
    margin-bottom: 28px;
    @media(min-width: 992px) {
        width: 248px;
        padding: 0 5px;
    }
}

.select-custom {
    .select2-container {
        width: 100% !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 20px;
    }

    .select2-selection--single {
        font-family: "ProximaNova-Semibold";
        background: white;
        margin: 0;
        border: none;
        height: 52px;
        line-height: 40px;
        color: #A4A4A4;
        font-size: 15px;
        text-transform: none;
        position: relative;
        border-radius: 3px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        border: 2px solid #6FC727;
        box-shadow: inset 0 0 15px #6FC727;
        border-radius: 3px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        position: absolute;
        width: 12px;
        height: 8px;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        background: url("../img/arrow.png") no-repeat center;

        b {
            display: none;
        }
    }
}

.select-custom.error {
    border: 3px solid red;
    border-radius: 3px;
}

.header-desktop {
    @media(max-width: 991px) {
        display: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__main {
        padding: 6px 24px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__links {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        padding: 0 17px;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        background: #F5E35F;

        li {
            margin: 0 35px;
            @media(min-width: 1600px) {
                margin: 0 55px;
            }
        }

        a {
            font-size: 15px;
            display: inline-block;
            position: relative;
            color: #C00027;
            border-bottom: 1px dotted transparent;
            @media(min-width: 1280px) {
                font-size: 18px;
            }
            @media(min-width: 1366px) {
                font-size: 22px;
            }

            &:after {
                content: '';
                position: absolute;
                left: -40px;
                top: 50%;
                transform: translateY(-50%);
                width: 32px;
                height: 30px;
            }

            &:hover {
                border-bottom: 1px dotted #C00027;
            }
        }
    }
}

.autopsy-link {
    &:after {
        background: url("../img/keys-desc.png") no-repeat center;
        background-size: contain;
    }
}

.duplicate-link {
    &:after {
        background: url("../img/key.png") no-repeat center;
        background-size: contain;
    }
}

.chip-link {
    &:after {
        background: url("../img/rental.png") no-repeat center;
        background-size: contain;
    }
}

.repairs-link {
    &:after {
        background: url("../img/lock.png") no-repeat center;
        background-size: contain;
    }
}

.header-desktop__logo {
    max-width: 250px;
    width: 100%;
    @media(min-width: 1100px) {
        margin-bottom: 14px;
    }
}

.logo-article {
    font-family: "ProximaNova-Semibold";
    font-size: 15px;
    color: #495057;

    span {
        color: #4FBD14;
    }
}

.search {
    max-width: 608px;
    width: 100%;
    @media(min-width: 1366px) {
        max-width: 508px;
        width: 100%;
    }
    @media(min-width: 1600px) {
        max-width: 420px;
        width: 100%;
    }
    @media(min-width: 1800px) {
        max-width: 608px;
        width: 100%;
    }

    label {
        display: inline-block;
        width: 100%;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            background: url("../img/search.png") no-repeat center;
        }
    }

    input {
        font-family: "ProximaNova-Regular";
        font-size: 18px;
        background: #FFFFFF;
        border: 2px solid #FFE033;
        border-radius: 3px 0 0 3px;
        height: 45px;
        padding-left: 55px;
        padding-right: 10px;
        width: calc(100% - 132px);
        color: #808080;
    }
}

.header-desktop__cont {
    text-align: right;
    @media(min-width: 1100px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
    }
    @media(min-width: 1600px) {
        display: block;
    }
}

.work-time {
    font-family: "ProximaNova-Semibold";
    font-size: 14px;
    margin-bottom: 5px;
    @media(min-width: 1100px) {
        margin-bottom: 0;
    }
    @media(min-width: 1600px) {
        margin-bottom: 5;
    }
}

.header-desktop__call {
    font-family: "ProximaNova-Semibold";
    font-size: 34px;
    color: #6FC727;
    @media(min-width: 1100px) {
        display: inline-block;
        margin: 0 42px 0 15px;
        font-size: 26px;
    }
    @media(min-width: 1600px) {
        margin: 0;
        font-size: 34px;
    }

    span {
        color: #C00227;
    }
}

.request-call {
    a {
        position: relative;
        color: #495057;
        border-bottom: 1px dotted #495057;

        &:hover {
            border-bottom: 1px dotted transparent;
        }

        &:after {
            content: '';
            position: absolute;
            left: -24px;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            background: url("../img/call_header.png") no-repeat center;
            background-size: contain;
        }
    }
}

.btn-search {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 132px;
    height: 45px;
    background: #FADB2F;
    border-radius: 0 5px 5px 0;
    text-align: center;
    font-size: 22px;
    color: #000000;
    border: none;
    transition: 0.5s;

    &:hover {
        background: #FFEB3F;
    }
}

.main-menu {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    @media(min-width: 992px) {
        padding-left: 35px;
    }

    li {
        &:not(:last-child) {
            margin-right: 88px;
        }
    }

    a {
        color: #495057;
        font-size: 18px;
        position: relative;

        &:hover {
            border-bottom: 1px dotted #495057;
        }

        &:after {
            content: '';
            position: absolute;
            left: -35px;
            top: -3px;
            width: 24px;
            height: 24px;
        }
    }

    &__price {
        &:after {
            background: url("../img/price.png") no-repeat center;
        }
    }

    &__about {
        &:after {
            background: url("../img/about-icon.png") no-repeat center;
        }
    }

    &__contact {
        &:after {
            background: url("../img/contacts.png") no-repeat center;
        }
    }
}

.burger {
    width: 30px;
    height: 26px;
    cursor: pointer;
    background: url("../img/burger.png") no-repeat center;
}

.burger.active {
    background: url("../img/close.png") no-repeat center;
    background-size: contain;
}

.points-icon {
    width: 30px;
    height: 30px;
    background: url("../img/thing.png") no-repeat center;
}

.menu-about {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    text-align: center;
    margin-bottom: 14px;
    padding-top: 10px;
    @media(max-width: 374px) {
        font-size: 16px;
    }

    span {
        color: #4FBD14;
    }
}

.logo-mob {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;

    &__img {
        width: 150px;
        position: relative;
        top: 2px;
    }
}

.header-mobile {
    height: 93px;
    @media(min-width: 375px) {
        height: 105px;
    }
    @media(min-width: 992px) {
        display: none;
    }

    &__cont {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        background: #FF4345;
        padding: 12px 16px;

        a {
            font-family: "ProximaNova-Semibold";
            color: white;
            font-size: 18px;
        }
    }

    &__menu {
        background: #FEDF3B;
        padding: 6px 16px 5px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 100;
    }
}

.header-mobile__hidden {
    position: fixed;
    width: 100%;
    z-index: 50;
    bottom: 0;
    overflow-y: auto;
    padding: 16px;
    background: #FEDF3B;
    display: none;
    @media(max-width: 767px) {
        height: calc(100% - 105px);
    }
    @media(min-width: 768px) {
        top: 104px;
    }

    .search {
        max-width: 400px;
        margin: 0 auto;

        input {
            width: 100%;
        }
    }
    @media(min-width: 992px) {
        display: none !important;
    }
}

.stickytop {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
}

.header-mobile__hidden.sticky {
    top: 58px;
    height: calc(100% - 60px);
}

.banner {
    background: url("../img/header-bg.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    padding: 12px 0;

    .container {
        padding: 0;
        @media(min-width: 992px) {
            padding: 0 10px;
        }
    }
    @media(min-width: 992px) {
        padding: 63px 0 76px;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(25, 4, 32, 0.75);
        z-index: 0;
    }

    .container {
        position: relative;
        z-index: 10;
    }
}

.main-menu.main-menu_mobile {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 35px;
    @media(min-width: 375px) {
        padding-left: 32px;
        max-width: 332px;
        width: 100%;
    }
    @media(min-width: 768px) {
        max-width: 832px;
        padding-left: 46px;
    }

    .request-call {
        a {
            color: #FF4345;

            &:after {
                top: 50%;
            }
        }
    }

    li {
        width: 100%;
        margin: 0 0 20px;

        a {
            font-size: 18px;
        }
        @media(min-width:375px) {
            width: 50%;
        }
        @media(min-width: 768px) {
            width: 25%;
        }
    }
}

.header-desktop__links_mob {
    background: none;
    padding: 0;
    flex-direction: column;
    @media(min-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    li {
        width: 100%;
        margin: 0 0 20px;
        @media(min-width: 768px) {
            width: 48%;
        }
    }

    a {
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 15px 0;
        background: #FFF1A6;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        text-align: center;

        &:after {
            left: 7px;
            @media(min-width: 375px) {
                left: 20px;
            }
        }
    }

    .autopsy-link:after {
        background: url("../img/car-key.png") no-repeat center;
    }
}

.footer {
    border-top: 5px solid #E63141;
    background: #FFF1A7;
}

.footer-top {
    border-bottom: 1px solid #DCCC75;
    margin-bottom: 28px;
}

.footer__nav {
    padding-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 375px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
    }
    @media(min-width: 500px) {
        padding-top: 0;
    }

    li {
        margin-bottom: 20px;
        @media(min-width: 375px) {
            width: 50%;
        }
        @media(min-width: 500px) {
            width: auto;
            margin-right: 20px;
            margin-bottom: 15px;
        }
        @media(min-width: 1366px) {
            margin-right: 40px;
        }
        @media(min-width: 1500px) {
            margin-right: 60px;
        }
    }

    a {
        font-family: "ProximaNova-Semibold";
        font-size: 18px;
        display: inline-block;
        position: relative;
        padding-left: 30px;
        color: #7F7F7F;

        &:hover {
            color: #FF4345;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-size: contain;
        }
    }
}

.footer__links {
    @media(min-width: 500px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }

    ul {
        max-width: 240px;
        margin-bottom: 34px;
        @media(min-width: 500px) {
            padding: 5px;
        }
        @media(min-width: 1366px) {
            padding: 0;

            &:not(:last-child) {
                margin-right: 60px;
            }
        }
        @media(min-width: 1500px) {
            &:not(:last-child) {
                margin-right: 80px;
            }
        }
    }

    li {
        margin-bottom: 16px;
    }

    a {
        font-size: 18px;
        color: #7F7F7F;
        display: inline-block;
        border-bottom: 1px dotted transparent;

        &:hover {
            color: #FF4345;
            border-bottom: 1px dotted #FF4345;
        }
    }
}

.main-menu__question:after {
    background: url("../img/question.png") no-repeat center;
}

.main-menu__garant:after {
    background: url("../img/garant.png") no-repeat center;
}

.footer-top__link {
    @media(min-width: 500px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        margin-right: 10px;
        @media(min-width: 768px) {
            margin-right: 20px;
        }
        @media(min-width: 992px) {
            margin-right: 60px;
        }
    }

    a {
        font-size: 18px;
        position: relative;
        display: inline-block;
        color: #005BD1;
        padding: 6px 0;
        border-bottom: 4px solid transparent;
        @media(min-width: 768px) {
            padding: 12px 0;
        }

        &:hover {
            border-bottom: 4px solid #005BD1;
        }
    }
}

.footer__logo {
    width: 222px;
    @media(max-width: 767px) {
        display: none;
    }
}

.footer-main {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__item {
        &:first-child {
            @media(max-width: 991px) {
                order: 1;
                text-align: center;
            }
        }

        &:nth-child(2) {
            @media(min-width: 992px) {
                margin: 0 15px;
            }
        }
    }
}

.metrica-mobile {
    padding: 12px 0;
    @media(min-width: 768px) {
        display: none;
    }
}

.footer__slogan {
    width: 150px;
    color: #FF4345;
    font-size: 25px;
    margin-bottom: 17px;
    @media(max-width: 991px) {
        width: auto;
    }
}

.payment-card {
    span {
        color: #7F7F7F;
        display: inline-block;
        padding-left: 5px;
    }
}

.footer__info {
    text-align: center;
    @media(min-width: 992px) {
        text-align: left;
        width: 260px;
    }

    li {
        margin-bottom: 11px;
    }
}

.footer__call {
    font-family: "ProximaNova-Bold";
    font-size: 28px;
    color: #FF4345;
    display: inline-block;
    position: relative;
    padding-left: 37px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        background: url("../img/tel.png") no-repeat center;
    }
}

.footer__email {
    font-family: "ProximaNova-Semibold";
    color: #7F7F7F;
    font-size: 18px;
    position: relative;
    display: inline-block;
    padding-left: 26px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: url("../img/email.png") no-repeat center;
    }

    span {
        border-bottom: 1px solid #7F7F7F;
    }
}

.footer__time {
    font-size: 18px;
    color: #7F7F7F;
}

.footer__city {
    font-family: "ProximaNova-Semibold";
    color: #7F7F7F;
    font-size: 22px;
    display: inline-block;
    position: relative;
    padding-left: 28px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: url("../img/punct.png") no-repeat center;
    }
}

.footer-bottom {
    padding: 12px 0 7px;
    border-top: 1px solid #DCCC75;
    @media(max-width: 767px) {
        background: #FF4345;
        padding: 10px 0;
        text-align: center;
        border-top: none;
    }
    @media(min-width: 768px) {
        margin-top: 20px;

        .container {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.copy {
    font-size: 14px;
    color: #7F7F7F;
    margin-bottom: 10px;
    @media(max-width: 767px) {
        font-family: "ProximaNova-Bold";
        text-align: center;
        color: white;
        margin-bottom: 0;
    }
    @media(min-width: 768px) {
        margin-bottom: 0;
    }
}

.metrica {
    @media(max-width: 767px) {
        display: none;
    }
}

.footer__adress {
    font-size: 18px;
    color: #7F7F7F;
}

.steps {
    margin-bottom: 45px;

    .main-title {
        margin-bottom: 40px;
    }
}

.steps__list {
    margin-bottom: 20px;
    max-width: 485px;
    @media(min-width: 992px) {
        padding: 5px;
    }

    li {
        position: relative;
        padding-left: 65px;
        margin-bottom: 20px;
        font-size: 18px;
        color: #495057;
        @media(max-width: 767px) {
            font-size: 15px;
        }

        b {
            font-family: "ProximaNova-Semibold";
        }

        a {
            color: #0B5CD0;
            border-bottom: 1px dotted #0B5CD0;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            background: url("../img/check-solid.png") no-repeat center;
        }

        &:not(:first-child):after {
            left: 15px;
        }

        &:first-child {
            font-size: 16px;
            color: #C00027;
            max-width: 380px;
            @media(min-width: 375px) {
                font-size: 18px;
            }
            @media(min-width: 768px) {
                font-size: 20px;
            }
            @media(min-width: 1280px) {
                font-size: 22px;
            }

            &:after {
                width: 50px;
                height: 50px;
                font-family: "ProximaNova-Bold";
                font-size: 22px;
                background: #00BEB4;
                color: white;
                text-align: center;
                line-height: 50px;
            }
        }
    }

    .steps__list_first:after {
        content: '1';
    }

    .steps__list_second:after {
        content: '2';
    }

    .steps__list_third:after {
        content: '3';
    }
}

.steps__inner {
    @media(min-width: 992px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }
}

.price-service__top {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    padding: 18px 0;
    margin-bottom: 22px;
    border-bottom: 2px solid #FFE033;
    color: #374957;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    @media(max-width: 767px) {
        padding: 0;

        li {
            display: none;
        }
    }
    @media(min-width: 992px) {
        li {
            &:first-child {
                padding-left: 50px;
            }

            &:last-child {
                padding-right: 50px;
            }
        }
    }
    @media(min-width: 1500px) {
        li {
            &:first-child {
                padding-left: 100px;
            }

            &:last-child {
                padding-right: 100px;
            }
        }
    }
}

.price-list {
    border-bottom: 2px solid #FFE033;
    margin-bottom: 18px;

    .price-list__item {
        padding-bottom: 13px;
        border-bottom: 1px solid #DEEAFB;

        &:not(:last-child) {
            margin-bottom: 22px;
        }
    }
}

.price-list__old {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    color: #A4A4A4;
    position: relative;
    margin-right: 20px;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #C00027;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.price-list__inner {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    @media(min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media(min-width: 1600px) {
        padding: 0 30px;
    }
}

.price-list__total {
    text-align: center;
    padding: 13px 0;
    @media(min-width: 768px) {
        width: 30%;
        padding: 0;
        text-align: right;
    }
    @media(min-width: 1600px) {
        width: 25%;
    }
}

.price-list__new {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    color: #374957;
    background: #FFF1A6;
    border-radius: 30px;
    padding: 4px 13px;
}

.tooltip-icon {
    position: absolute;
    cursor: pointer;
    width: 23px;
    height: 25px;
    right: 0;
    top: -16px;
    background: url("../img/tooltip.png") no-repeat center;
    @media(max-width: 767px) {
        display: none;
    }
    @media(min-width: 1600px) {
        right: 30px;
    }
}

.tooltip {
    position: absolute;
    right: -150px;
    bottom: calc(100% + 36px);
    max-width: 200px;
    font-size: 15px;
    color: #7F7F7F;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.25);
    padding: 9px 10px;
    display: none;
    @media(min-width: 1600px) {
        right: -120px;
    }

    &__inner {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 17px;
            bottom: -65%;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 18px solid white;
        }
    }
}

.tooltip.active {
    display: block;
}

.price-list__article {
    font-size: 20px;
    color: #495057;
    margin-bottom: 5px;
    position: relative;
    @media(max-width: 991px) {
        font-size: 16px;
    }
    @media(min-width: 768px) {
        padding-right: 26px;
        width: 70%;
    }
    @media(min-width: 1600px) {
        width: 75%;
    }
}

.price-list__link {
    order: 1;

    a {
        display: inline-block;
        color: #D13980;
        border-bottom: 1px dotted #D13980;
        @media(max-width: 767px) {
            font-size: 15px;
        }

        &:hover {
            border-bottom: 1px dotted transparent;
        }

        &:first-child {
            margin-right: 24px;
        }
    }
}

.qa-block {
    padding: 40px 0 52px;
}

.qa-block__title {
    font-family: "ProximaNova-Semibold";
    font-size: 28px;
    color: white;
    background: #6FC727;
    box-shadow: 0 5px 8px rgba(157, 157, 157, 0.25);
    border-radius: 10px 10px 0 0;
    text-align: center;
    padding: 8px 0;
}

.qa-block__list {
    li {
        padding: 18px 10px 18px 15px;
        border: 1px solid #6FC727;
        border-radius: 10px;
        font-size: 14px;

        &:first-child {
            border-radius: 0 0 10px 10px;
        }
        @media(min-width: 992px) {
            font-size: 18px;
            padding: 40px 30px 40px 40px;
        }
    }
}

.qa-block__article {
    position: relative;
    padding-right: 38px;
    @media(min-width: 992px) {
        font-family: "ProximaNova-Semibold";
        padding-left: 80px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 78px;
            background: url("../img/quest.png") no-repeat center;
        }
    }
}

.qa-block__btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: url("../img/plus.png") no-repeat center;
    background-size: contain;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    @media(min-width: 992px) {
        width: 40px;
        height: 40px;
    }
    @media(min-width: 1280px) {
        top: -10px;
    }
}

.qa-block__btn.active {
    transform: rotate(45deg);
}

.qa-block__hidden {
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid #DEEAFB;
    display: none;
    position: relative;
    @media(min-width: 992px) {
        padding-left: 80px;
        padding-top: 36px;
        margin-top: 36px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-33%);
            width: 68px;
            height: 55px;
            background: url("../img/key-red.png") no-repeat center;
        }
    }
}

.about-us {
    background: #DEEAFB;
    margin-bottom: 55px;
    @media(max-width: 767px) {
        background: white;
    }
    @media(min-width: 768px) {
        padding-top: 48px;
    }
}

.about-us__title-mob {
    font-family: "ProximaNova-Semibold";
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
    @media(min-width: 768px) {
        display: none;
    }
}

.doc-slider__img {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
}

.about-us__inner {
    .about-us__item {
        padding-bottom: 50px;
        @media(min-width: 1280px) {
            width: 49%;
        }

        &:last-child {
            @media(min-width: 1500px) {
                width: 45%;
            }
        }
    }
    @media(min-width: 1280px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }
}

.doc-slider,
.equip-slider {
    .owl-next,
    .owl-prev {
        position: absolute;
        width: 25px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
    }

    .owl-prev {
        left: 0;
    }

    .owl-next {
        right: 0;
    }

    .nav-left,
    .nav-right {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 25px;
            height: 40px;
            z-index: 10;
            opacity: 0.5;
        }
    }

    .nav-left:before {
        background: url("../img/str-left.png") no-repeat center;
    }

    .nav-right:before {
        background: url("../img/str-right.png") no-repeat center;
    }

    .owl-nav {
        height: 0;
    }

    .owl-dots.disabled {
        display: block;
        @media(min-width: 768px) {
            display: none;
        }
    }

    .owl-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -25px;
        @media(min-width: 768px) {
            display: none;
        }

        .owl-dot {
            display: inline-block;
            margin-right: 10px;

            span {
                float: left;
                display: inline-block;
                border-radius: 10em;
                background: #fff;
                width: 10px;
                height: 10px;
                background: #DEEAFB;
            }
        }

        .active {
            span {
                background: #E63141;
            }
        }
    }
}

.doc-slider {
    .item {
        background: white;
        padding-top: 35px;
    }
}

.equip-slider {
    @media(min-width: 768px) {
        background: white;
        box-shadow: 0 2px 30px rgba(113, 113, 113, 0.25);
        border-radius: 10px;
        padding: 50px;
    }
    @media(min-width: 992px) {
        .equip-slider__img {
            max-width: 286px;
            margin: 0 auto;
        }
        padding: 83px 45px;
        height: 400px;

        .item {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;

            .equip-slider__article,
            .equip-slider__img {
                width: 50%;
            }
        }
    }

    img {
        max-width: 100%;
    }

    .owl-next,
    .owl-prev {
        top: 30%;
    }

    .owl-prev {
        left: 5px;
    }

    .owl-next {
        right: 5px;
    }

    .nav-left:before {
        opacity: 1;
        background: url("../img/left-white.png") no-repeat center;
    }

    .nav-right:before {
        opacity: 1;
        background: url("../img/right-white.png") no-repeat center;
    }

    .owl-dots,
    .owl-dots.disabled {
        display: block;
        @media(min-width: 768px) {
            bottom: 25px;
        }
        @media(min-width: 992px) {
            bottom: 50px;
        }
    }
}

.about-us__title {
    font-family: "ProximaNova-Semibold";
    font-size: 28px;
    color: #495057;
    margin-bottom: 20px;
    text-align: center;
    @media(max-width: 767px) {
        display: none;
    }
}

.doc-slider__article {
    font-family: "ProximaNova-Semibold";
    font-size: 18px;
    color: #000000;
    text-align: center;
    padding: 26px 0 38px;
    display: none;
    @media(min-width: 768px) {
        display: block;
        color: #495057;
    }
}

.equip-slider__article {
    font-size: 18px;
    color: #7F7F7F;
    padding: 13px 15px;
    @media(min-width: 1280px) {
        padding: 0 0 0 30px;
    }
}

.equip-slider__title {
    font-family: "ProximaNova-Semibold";
    text-align: center;
    font-size: 18px;
    color: #000000;
    margin-bottom: 5px;
    @media(min-width: 992px) {
        color: #495057;
        text-align: left;
        padding-left: 30px;
        margin-bottom: 14px;
        padding-left: 0;
    }
}

.form-wrap {
    .main-title {
        margin-bottom: 28px;
        @media(max-width: 767px) {
            display: none;
        }
    }
}

.form {
    position: relative;
    background: #FEDF3B;
    @media(min-width: 992px) {
        background: #FEDF3B url("../img/bg-red.png") no-repeat right top;
        background-size: contain;
    }
}

.man {
    position: absolute;
    width: 240px;
    height: 368px;
    background: url("../img/man.png") no-repeat center;
    bottom: 0;
    left: 20px;
    @media(max-width: 1279px) {
        display: none;
    }
    @media(min-width: 1920px) {
        left: 220px;
    }
}

.form__action {
    color: white;
    background: #E63141 url("../img/big-key.png") no-repeat 34px 43px;
    padding: 18px 30px 22px;
    @media(min-width: 768px) {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    @media(min-width: 992px) {
        width: 300px;
        background: url("../img/big-key.png") no-repeat 34px 43px;
        right: 0;
        left: auto;
        top: 15px;
    }
    @media(min-width: 1600px) {
        height: 260px;
        right: 36px;
        background: url("../img/bigger-key.png") no-repeat 4px 4px;
        top: 50%;
        transform: translateY(-50%);
    }

    li {
        text-align: right;

        &:first-child {
            font-size: 28px;
        }

        &:last-child {
            font-size: 96px;
        }
    }
}

.form__title-mob {
    font-family: "ProximaNova-Semibold";
    font-size: 20px;
    color: #000000;
    margin-bottom: 14px;
    text-align: center;
    @media(min-width: 768px) {
        display: none;
    }
}

.form__title {
    text-align: center;
    font-size: 18px;
    color: #000000;
    margin-bottom: 25px;
    @media(min-width: 768px) {
        text-align: left;
    }
    @media(min-width: 992px) {
        margin-bottom: 40px;
    }
    @media(min-width: 1366px) {
        font-size: 22px;
    }
}

.form__inner {
    padding: 24px 15px;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
    }
    @media(min-width: 992px) {
        justify-content: flex-start;
    }
    @media(min-width: 1280px) {
        justify-content: center;
    }

    .form__item {
        @media(min-width: 768px) {
            width: 352px;
        }
        @media(min-width: 992px) {
            &:first-child {
                padding-top: 42px;
                margin-right: 10px;
            }

            &:last-child {
                padding: 0 10px;
            }
        }
        @media(min-width: 1366px) {
            &:first-child {
                max-width: 480px;
                width: 100%;
            }
        }
        @media(min-width: 1600px) {
            &:first-child {
                margin-right: 86px;
            }

            &:last-child {
                padding: 0;
            }
        }
    }
}

.form__list {
    li {
        position: relative;
        padding-left: 18px;
        color: #495057;
        margin-bottom: 18px;
        @media(max-width: 374px) {
            font-size: 15px;
        }
        @media(min-width: 992px) {
            margin-bottom: 30px;
        }
        @media(min-width: 1366px) {
            font-size: 22px;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background: url("../img/circle.png") no-repeat center;
        }
    }
}

.form__inp {
    font-family: "ProximaNova-Regular";
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 3px;
    background: white;
    padding-left: 20px;
    color: #999999;
    font-size: 18px;
    margin-bottom: 20px;
}

.form__textarea {
    font-family: "ProximaNova-Regular";
    border: none;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    background: white;
    color: #999999;
    resize: none;
    height: 107px;
    padding-top: 14px;
    padding-left: 20px;
    margin-bottom: 20px;
}

.form__btn-wrap {
    margin-bottom: 21px;
    text-align: center;
}

.form__btn {
    font-family: "ProximaNova-Semibold";
    max-width: 343px;
    width: 100%;
    background: linear-gradient(96.53deg, #F42628 1.77%, #C10027 96.64%);
    box-shadow: 0 2px 15px #FF7D7E;
    border-radius: 60px;
    border: none;
    text-align: center;
    height: 60px;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

.more-service.container {
    max-width: 1760px;
}

.more-service__list {
    padding-top: 20px;
    @media(min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    a {
        display: inline-block;
        color: #3D7AB6;
        padding: 8px 34px;
        border-radius: 5px;

        &:hover {
            color: white;
            background: #535E8A;
        }
    }
}

// Выберите модель ========================= */

.map-wrap {
    padding-top: 0;
    margin-bottom: 30px;
}

.models-link {
    max-width: 1372px;

    .main-title {
        text-align: left;
        margin-bottom: 50px;
    }
}

.more-service {
    margin-bottom: 47px;
}

.models-link__list {
    display: flex;
    flex-wrap: wrap;

    li {
        margin-right: 25px;
        margin-bottom: 25px;
        @media(min-width: 1500px) {
            margin-right: 70px;
            margin-bottom: 40px;
        }
    }

    a {
        font-family: "ProximaNova-Regular";
        font-size: 24px;
        color: #495057;
        display: inline-block;
        padding: 6px 10px;
        border-radius: 5px;
        border-bottom: 1px solid white;
        position: relative;

        &:hover {
            color: white;
            background: #535E8A;
            box-shadow: 0 0 20px rgba(65, 80, 150, 0.46);

            span {
                border-bottom: 1px solid white;
            }
        }
    }
}
